import React from "react";
import { Route, Switch, Redirect, withRouter, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { 
  APP_PREFIX_PATH, 
  AUTH_PREFIX_PATH, 
  GE_STAGING_URL, 
  GE_PROD_URL, 
  GE_API_STAGING_URL, 
  GE_API_PROD_URL, 
  GE_HRMS_STAGING_URL, 
  GE_HRMS_PROD_URL 
} from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import TaskPrintLayout from "layouts/task-print-layout";
import axios from 'axios';

export const Views = (props) => {
  const { locale, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  // const name = new URLSearchParams(search).get('name');
  // const email = new URLSearchParams(search).get('email');
  // const profile_pic = new URLSearchParams(search).get('profile_pic');
  const param = useParams()
  const tok = localStorage.getItem('token');
  console.log(token);
  // let url_base = window.location.href;
  
  useBodyClass(`dir-${direction}`);

  const getUserDetail = async ()=> {
    axios({
      method: 'get',
      url: "/api/getLoggedInUsersDetails",
      headers: {
          Authorization: `Bearer ${tok}`
      },
      data: {}
    }).then((response) => {
        const res = response.data.user_details
        localStorage.setItem("name", res.name);
        localStorage.setItem("email", res.email);
        localStorage.setItem("profile_pic", res.profile_pic);
    }).catch((err) => {
        console.log(err)
    });
  }
  
  React.useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      console.log(token);
    } 
    // else {
    //   localStorage.setItem("token", 
    //     'Y2xveDZiMG1oMDAwMXJreGEwZW04MGtxcg.rD8fABp_ujELcRHA1e8je4dPUwSpyckEQjXrldKWHR39jz-FBE2YwL7SI6td');
    // }
    getUserDetail()
    
    // if(url_base.includes(TC_INTERNAL_URL)) {
    //   axios.defaults.baseURL = TC_API_INTERNAL_URL
    // }
    // if(url_base.includes(TC_STAGING_URL)) {
    //   axios.defaults.baseURL = TC_API_STAGING_URL
    // }
    // if(url_base.includes(TC_PROD_URL)) {
    //   axios.defaults.baseURL = TC_API_PROD_URL
    // }
  }, [])
  
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <Route path={APP_PREFIX_PATH}>
            <AppLayout direction={direction} location={location}/>
          </Route>
          <Route path={`/task-completion-report-print/:id`} exact>
            <TaskPrintLayout direction={direction} location={location} id={param.id}/>
          </Route>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } =  theme;
  const { token } = auth;
  return { locale, token, direction }
};

export default withRouter(connect(mapStateToProps)(Views));